export const specialSystemsConfig = {
  trixie: [2, 3],
  yankee: [2, 3, 4],
  canadian: [2, 3, 4, 5],
  heinz: [2, 3, 4, 5, 6],
  superHeinz: [2, 3, 4, 5, 6, 7],
  goliath: [2, 3, 4, 5, 6, 7, 8],
  patent: [1, 2, 3],
  lucky15: [1, 2, 3, 4],
  lucky31: [1, 2, 3, 4, 5],
  lucky63: [1, 2, 3, 4, 5, 6],
};

export function getAvailableMultiples(numOfSelections) {
  return Object.entries(specialSystemsConfig).reduce((acc, [key, values]) => {
    if (values[values.length - 1] === numOfSelections) acc.push(key);
    return acc;
  }, []);
}

export const constructSystemIdAndTranslation = (i) => {
  return (
    {
      1: { id: 'single', translation: 'betslip_multiples.types.single' },
      2: { id: 'double', translation: 'betslip_multiples.types.double' },
      3: { id: 'treble', translation: 'betslip_multiples.types.treble' },
    }[i] ?? {
      id: `${i}_fold`,
      translation: `betslip_multiples.folds.${i}`,
    }
  );
};

export function getAllBettingSystems(numOfSelections, pascalTriangle) {
  const systems = [];
  const pascalTrianglePerNumberOfSelections = pascalTriangle[numOfSelections];

  for (let i = 1; i <= Math.min(3, numOfSelections); i++)
    systems.push({
      ...constructSystemIdAndTranslation(i),
      systems: [i],
      combinations: pascalTrianglePerNumberOfSelections[i],
    });

  for (const system of getAvailableMultiples(numOfSelections))
    systems.push({
      id: system,
      translation: `betslip_multiples.types.${system}`,
      systems: specialSystemsConfig[system],
      combinations: specialSystemsConfig[system].reduce(
        (acc, n) => acc + pascalTrianglePerNumberOfSelections[n],
        0,
      ),
    });

  for (let i = 4; i <= numOfSelections; i++)
    systems.push({
      ...constructSystemIdAndTranslation(i),
      systems: [i],
      combinations: pascalTrianglePerNumberOfSelections[i],
    });

  return systems;
}
