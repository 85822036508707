<script setup>
import { toRef } from 'vue';
import { useStore } from '@/stores/store';

import Icon from '@/components/common/Icon.vue';
import AppModal from '@/components/common/AppModal.vue';
import Button from '@/components/common/Button.vue';
import Spinner from '@/components/common/Spinner.vue';
import useAutoCashoutSetup from '@/composables/bet-list/useAutoCashoutSetup';
import { formatNumberWithCurrency } from '@/utils/helpers';

const emit = defineEmits(['close']);
const props = defineProps({
  bet: {
    required: true,
    default: null,
    type: Object,
  },
  maxWin: {
    required: true,
    default: 0,
    type: Number,
  },
  action: {
    required: false,
    default: 'setup',
    type: String,
  },
});

const store = useStore();

const betRef = toRef(props, 'bet');

const {
  autoCashoutAmountInputRef,
  autoCashoutActionLoading,
  createRuleAvailable,
  autoCashoutAmount,
  onRuleDelete,
  onRuleCreate,
  handleAutoCashoutInput,
  handleAutoCashoutInputBlur,
} = useAutoCashoutSetup(betRef, props.maxWin, emit);
</script>

<template>
  <AppModal
    class="auto-cashout-modal-wrapper"
    width="480px"
    max-width="1200px"
    height="auto"
    @close-modal="emit('close')">
    <div class="auto-cashout-modal">
      <h4 class="auto-cashout-modal__title">
        <span v-if="action === 'setup'">{{ store.getTranslation('autocashout.setup.label') }}</span>
        <span v-if="action === 'delete'">{{
          store.getTranslation('autocashout.rule.delete')
        }}</span>

        <Icon
          icon="n-i-close-a"
          size="xl"
          @click="emit('close')" />
      </h4>

      <p class="auto-cashout-modal__description">
        <span v-if="action === 'setup'">{{
          store.getTranslation('autocashout.setup.description')
        }}</span>
        <span v-if="action === 'delete'">{{
          store.getTranslation('autocashout.rule.delete_description', {
            value: formatNumberWithCurrency(bet.autoCashoutRule?.amount, bet.currency),
          })
        }}</span>
      </p>

      <div
        v-if="action === 'setup'"
        class="auto-cashout-modal__amount">
        <input
          ref="autoCashoutAmountInputRef"
          type="text"
          :placeholder="store.getTranslation('amount')"
          :value="autoCashoutAmount"
          @input="handleAutoCashoutInput"
          @blur="handleAutoCashoutInputBlur" />

        <Icon
          icon="n-i-bet"
          size="xl" />
      </div>

      <div class="auto-cashout-modal__meta">
        <div class="auto-cashout-modal__meta-row">
          <div class="auto-cashout-modal__meta-label">{{ store.getTranslation('payment') }}</div>
          <div class="auto-cashout-modal__meta-value">
            {{ formatNumberWithCurrency(bet.payin?.stake?.amount, bet.currency) }}
          </div>
        </div>

        <div class="auto-cashout-modal__meta-row">
          <div class="auto-cashout-modal__meta-label">
            {{ store.getTranslation('possible_payout') }}
          </div>
          <div class="auto-cashout-modal__meta-value">
            {{ formatNumberWithCurrency(maxWin, bet.currency) }}
          </div>
        </div>

        <div class="auto-cashout-modal__meta-row">
          <div class="auto-cashout-modal__meta-label auto-cashout-modal__meta-label--highlighted">
            {{ store.getTranslation('cashout_available') }}
          </div>

          <div class="auto-cashout-modal__meta-value auto-cashout-modal__meta-value--highlighted">
            {{ formatNumberWithCurrency(bet.cashout.winnings.total, bet.currency) }}
          </div>
        </div>
      </div>

      <div class="auto-cashout-modal__actions">
        <Button
          class="auto-cashout-modal__cancel"
          @click="emit('close')">
          {{ store.getTranslation('cancel') }}
        </Button>

        <Button
          v-if="action === 'setup'"
          class="auto-cashout-modal__create"
          :disabled="!createRuleAvailable"
          @click="onRuleCreate">
          <span v-if="!autoCashoutActionLoading">{{
            store.getTranslation('autocashout.rule.create_action')
          }}</span>
          <Spinner
            v-else
            class="mini" />
        </Button>

        <Button
          v-if="action === 'delete'"
          class="auto-cashout-modal__delete"
          @click="onRuleDelete">
          <span v-if="!autoCashoutActionLoading">{{
            store.getTranslation('autocashout.rule.delete_action')
          }}</span>
          <Spinner
            v-else
            class="mini" />
        </Button>
      </div>
    </div>
  </AppModal>
</template>

<style>
:root {
  --auto-cashout-modal-create-button-bg-color: var(--primary);
  --auto-cashout-modal-create-button-color: var(--text-secondary-1);
  --auto-cashout-modal-delete-button-bg-color: var(--error);
  --auto-cashout-modal-delete-button-color: #efefef;
}
</style>

<style scoped lang="scss">
.auto-cashout-modal-wrapper {
  :deep(.app-modal) {
    padding: var(--gap-32);
  }

  .auto-cashout-modal {
    &__title {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: var(--font-large);
      font-weight: 700;
      margin-bottom: var(--gap-24);

      span {
        flex: 1;
      }

      .icon {
        cursor: pointer;
      }
    }

    &__description {
      font-size: var(--font-medium);
      margin-bottom: var(--gap-16);
    }

    &__amount {
      display: flex;
      align-items: center;
      height: 2.5rem;
      padding: var(--gap-10) 0;
      margin-bottom: var(--gap-24);
      border-bottom: 1px solid var(--text-primary-3);

      input {
        flex: 1;
        font-size: var(--font-base);
        font-weight: 500;
        background-color: transparent;
        color: var(--text-primary-1);
      }

      .icon {
        padding-left: var(--gap-16);
      }
    }

    &__meta {
      display: flex;
      flex-direction: column;
      gap: var(--gap-4);
      padding: var(--gap-base) var(--gap-12);
      background-color: var(--card-section-1);
      border-radius: var(--border-radius);
      margin-bottom: var(--gap-24);

      &-row {
        display: flex;
        align-items: center;
        font-size: var(--font-base);
      }

      &-label {
        flex: 1;
        color: var(--text-primary-2);

        &--highlighted {
          color: var(--primary);
          font-weight: 700;
        }
      }

      &-value {
        font-weight: 500;
        color: var(--text-primary-1);

        &--highlighted {
          color: var(--primary);
          font-weight: 700;
        }
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: var(--gap-base);
    }

    &__create {
      background-color: var(--auto-cashout-modal-create-button-bg-color);
      color: var(--auto-cashout-modal-create-button-color);
      font-weight: 500;

      &.btn--disabled {
        opacity: 0.3;
      }
    }

    &__delete {
      background-color: var(--auto-cashout-modal-delete-button-bg-color);
      color: var(--auto-cashout-modal-delete-button-color);
      font-weight: 500;
    }

    &__cancel {
      background-color: transparent;
      font-weight: 500;

      &:hover {
        background-color: var(--btn-bg-hover);
      }
    }
  }
}
</style>
