<script setup>
import { toRef } from 'vue';
import { useStore } from '@/stores/store';

import Icon from '@/components/common/Icon.vue';
import BottomSheet from '@/components/mobile/common/BottomSheet.vue';
import Button from '@/components/common/Button.vue';
import Spinner from '@/components/common/Spinner.vue';
import useAutoCashoutSetup from '@/composables/bet-list/useAutoCashoutSetup';
import { formatNumberWithCurrency } from '@/utils/helpers';

const emit = defineEmits(['close']);
const props = defineProps({
  bet: {
    required: true,
    default: null,
    type: Object,
  },
  maxWin: {
    required: true,
    default: 0,
    type: Number,
  },
  action: {
    required: false,
    default: 'setup',
    type: String,
  },
});

const store = useStore();

const betRef = toRef(props, 'bet');

const {
  autoCashoutAmountInputRef,
  autoCashoutActionLoading,
  autoCashoutAmount,
  createRuleAvailable,
  onRuleCreate,
  onRuleDelete,
  handleAutoCashoutInput,
  handleAutoCashoutInputBlur,
} = useAutoCashoutSetup(betRef, props.maxWin, emit);
</script>

<template>
  <BottomSheet @toggle-sheet="emit('close')">
    <div class="auto-cashout-drawer">
      <h4 class="auto-cashout-drawer__title">
        <span v-if="action === 'setup'">{{ store.getTranslation('autocashout.setup.label') }}</span>
        <span v-if="action === 'delete'">{{
          store.getTranslation('autocashout.rule.delete')
        }}</span>
      </h4>

      <p class="auto-cashout-drawer__description">
        <span v-if="action === 'setup'">{{
          store.getTranslation('autocashout.setup.description')
        }}</span>
        <span v-if="action === 'delete'">{{
          store.getTranslation('autocashout.rule.delete_description', {
            value: formatNumberWithCurrency(bet.autoCashoutRule?.amount, bet.currency),
          })
        }}</span>
      </p>

      <div
        v-if="action === 'setup'"
        class="auto-cashout-drawer__amount">
        <input
          ref="autoCashoutAmountInputRef"
          type="text"
          :placeholder="store.getTranslation('amount')"
          :value="autoCashoutAmount"
          @input="handleAutoCashoutInput"
          @blur="handleAutoCashoutInputBlur" />

        <Icon
          icon="n-i-bet"
          size="xl" />
      </div>

      <div class="auto-cashout-drawer__meta">
        <div class="auto-cashout-drawer__meta-row">
          <div class="auto-cashout-drawer__meta-label">{{ store.getTranslation('payment') }}</div>
          <div class="auto-cashout-drawer__meta-value">
            {{ formatNumberWithCurrency(bet.payin?.stake?.amount, bet.currency) }}
          </div>
        </div>

        <div class="auto-cashout-drawer__meta-row">
          <div class="auto-cashout-drawer__meta-label">
            {{ store.getTranslation('possible_payout') }}
          </div>
          <div class="auto-cashout-drawer__meta-value">
            {{ formatNumberWithCurrency(maxWin, bet.currency) }}
          </div>
        </div>

        <div class="auto-cashout-drawer__meta-row">
          <div class="auto-cashout-drawer__meta-label auto-cashout-drawer__meta-label--highlighted">
            {{ store.getTranslation('cashout_available') }}
          </div>

          <div class="auto-cashout-drawer__meta-value auto-cashout-drawer__meta-value--highlighted">
            {{ formatNumberWithCurrency(bet.cashout.winnings.total, bet.currency) }}
          </div>
        </div>
      </div>

      <div class="auto-cashout-drawer__actions">
        <Button
          v-if="action === 'setup'"
          class="auto-cashout-drawer__create"
          block
          :disabled="!createRuleAvailable"
          @click="onRuleCreate">
          <span v-if="!autoCashoutActionLoading">{{
            store.getTranslation('autocashout.rule.create_action')
          }}</span>
          <Spinner
            v-else
            class="mini" />
        </Button>

        <Button
          v-if="action === 'delete'"
          class="auto-cashout-drawer__delete"
          block
          @click="onRuleDelete">
          <span v-if="!autoCashoutActionLoading">{{
            store.getTranslation('autocashout.rule.delete_action')
          }}</span>
          <Spinner
            v-else
            class="mini" />
        </Button>

        <Button
          class="auto-cashout-drawer__cancel"
          block
          @click="emit('close')">
          {{ store.getTranslation('cancel') }}
        </Button>
      </div>
    </div>
  </BottomSheet>
</template>

<style>
:root {
  --auto-cashout-drawer-create-button-bg-color: var(--primary);
  --auto-cashout-drawer-create-button-color: var(--text-secondary-1);
  --auto-cashout-drawer-delete-button-bg-color: var(--error);
  --auto-cashout-drawer-delete-button-color: #efefef;
}
</style>

<style lang="scss" scoped>
.auto-cashout-drawer {
  &__title {
    font-size: var(--font-big);
    font-weight: 700;
    margin-bottom: var(--gap-16);
  }

  &__description {
    font-size: var(--font-base);
    color: var(--text-primary-2);
    margin-bottom: var(--gap-base);
  }

  &__amount {
    display: flex;
    align-items: center;
    height: 2.5rem;
    padding: var(--gap-10) 0;
    margin-bottom: var(--gap-16);
    border-bottom: 1px solid var(--text-primary-3);

    input {
      flex: 1;
      font-size: var(--font-base);
      font-weight: 500;
      background-color: transparent;
      color: var(--text-primary-1);
    }

    .icon {
      padding-left: var(--gap-16);
    }
  }

  &__meta {
    display: flex;
    flex-direction: column;
    gap: var(--gap-4);
    padding: var(--gap-base) var(--gap-12);
    background-color: var(--card-section-1);
    border-radius: var(--border-radius);
    margin-bottom: var(--gap-24);

    &-row {
      display: flex;
      align-items: center;
      font-size: var(--font-small);
    }

    &-label {
      flex: 1;
      color: var(--text-primary-2);

      &--highlighted {
        color: var(--primary) !important;
      }
    }

    &-value {
      font-weight: 500;
      color: var(--text-primary-1);

      &--highlighted {
        color: var(--primary) !important;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: var(--gap-base);
  }

  &__create {
    background-color: var(--auto-cashout-drawer-create-button-bg-color);
    color: var(--auto-cashout-drawer-create-button-color);
    font-weight: 500;

    &.btn--disabled {
      opacity: 0.3;
    }
  }

  &__delete {
    background-color: var(--auto-cashout-drawer-delete-button-bg-color);
    color: var(--auto-cashout-drawer-delete-button-color);
    font-weight: 500;
  }

  &__cancel {
    background-color: transparent;
    font-weight: 500;
  }
}

:deep(.bottom-sheet__content) {
  padding-top: 0;
}
</style>
