import { ref, computed, toRef } from 'vue';
import { useStore } from '@/stores/store';
import { formatNumberInput } from '@/utils/helpers';

export default function useAutoCashoutSetup(bet, maxWin, emit) {
  const store = useStore();

  const betData = toRef(bet);

  const autoCashoutAmount = ref(null);
  const autoCashoutActionLoading = ref(false);
  const autoCashoutAmountInputRef = ref(null);

  const createRuleAvailable = computed(() => {
    return (
      +autoCashoutAmount.value <= maxWin &&
      +autoCashoutAmount.value > betData.value.cashout.winnings.total
    );
  });

  const resetInputValue = () => {
    autoCashoutAmount.value = null;
  };

  const onRuleCreate = async () => {
    if (!createRuleAvailable.value) return false;

    autoCashoutActionLoading.value = true;
    const isRuleCreated = await store.createAutoCashoutRule({
      id: betData.value.id,
      amount: autoCashoutAmount.value,
      betProfileId: betData.value.betProfileId,
    });
    autoCashoutActionLoading.value = false;

    if (isRuleCreated && emit) emit('close');

    return isRuleCreated;
  };

  const onRuleDelete = async () => {
    autoCashoutActionLoading.value = true;
    const isRuleDeleted = await store.removeAutoCashoutRule(betData.value);
    autoCashoutActionLoading.value = false;

    if (isRuleDeleted && emit) emit('close');
  };

  const handleAutoCashoutInput = (event) => {
    const amountValue = formatNumberInput(event.target.value);
    autoCashoutAmountInputRef.value.value = amountValue;
    autoCashoutAmount.value = amountValue;
  };

  const handleAutoCashoutInputBlur = (event) => {
    const { value = '' } = event.target;
    if (!value) return;

    autoCashoutAmount.value = parseFloat(value).toFixed(2);
  };

  return {
    autoCashoutAmountInputRef,
    autoCashoutActionLoading,
    createRuleAvailable,
    autoCashoutAmount,
    onRuleCreate,
    onRuleDelete,
    resetInputValue,
    handleAutoCashoutInput,
    handleAutoCashoutInputBlur,
  };
}
