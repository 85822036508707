import { v4 as uuidv4 } from 'uuid';

const formatComboBets = (rawSelections, stake) => {
  const selections = rawSelections.reduce((acc, selection) => {
    if (!selection.active) return acc;

    acc.push({
      type: selection.type === 'bet_builder' ? 'CUSTOM' : 'REGULAR',
      odds: selection.odds,
      banker: false,
      game: selection.game,
      eventMarketOutcomes: [...selection.markets].map(([, outcome]) => {
        return {
          eventId: selection.eventId,
          eventMarketId: outcome.marketId,
          eventMarketOutcomeId: outcome.outcomeId,
          odds: outcome.odds,
        };
      }),
    });
    return acc;
  }, []);

  return [
    {
      selectedSystems: [selections.length],
      payin: {
        stake: {
          type: 'TOTAL',
          amount: stake,
        },
      },
      selections,
    },
  ];
};

const formatSingleBets = (selections, stake) => {
  const splitStake = parseFloat((parseFloat(stake) / selections.length || 1).toFixed(2));

  return selections.reduce((acc, selection) => {
    if (!selection.active) return acc;

    acc.push({
      selectedSystems: [1],
      payin: {
        stake: {
          type: 'TOTAL',
          amount: selection.payment || splitStake,
        },
      },
      selections: [
        {
          type: selection.type === 'bet_builder' ? 'CUSTOM' : 'REGULAR',
          odds: selection.odds,
          banker: false,
          game: selection.game,
          eventMarketOutcomes: [...selection.markets].map(([, outcome]) => {
            return {
              eventId: selection.eventId,
              eventMarketId: outcome.marketId,
              eventMarketOutcomeId: outcome.outcomeId,
              odds: outcome.odds,
            };
          }),
        },
      ],
    });
    return acc;
  }, []);
};

const formatSystemBets = (selections, stake, systems) => {
  const selectionList = Object.values(selections ?? {});
  const systemsList = Object.values(systems ?? {}).filter((system) => system.selected) || [];

  const stakePerSystem = systemsList.filter((system) => system.stake);

  if (!systemsList.length) return;
  const numOfBankers = selectionList.filter(
    (selection) => selection.active && selection.banker,
  ).length;

  if (stakePerSystem?.length <= 1) {
    return [
      {
        selectedSystems: [systemsList.reduce((acc, n) => (acc += +n.id), numOfBankers)],
        payin: {
          stake: {
            type: 'TOTAL',
            amount: stake,
          },
        },
        selections: selections.reduce((acc, selection) => {
          if (!selection.active) return acc;

          acc.push({
            type: selection.type === 'bet_builder' ? 'CUSTOM' : 'REGULAR',
            odds: selection.odds,
            banker: selection.banker || false,
            game: selection.game,
            eventMarketOutcomes: [...selection.markets].map(([, outcome]) => {
              return {
                eventId: selection.eventId,
                eventMarketId: outcome.marketId,
                eventMarketOutcomeId: outcome.outcomeId,
                odds: outcome.odds,
              };
            }),
          });

          return acc;
        }, []),
      },
    ];
  }

  return systemsList.map((system) => ({
    selectedSystems: [system.id + numOfBankers],
    payin: {
      stake: {
        type: 'TOTAL',
        amount: system.stake || 1,
      },
    },
    selections: selectionList.reduce((acc, selection) => {
      if (!selection.active) return acc;

      acc.push({
        type: 'REGULAR',
        odds: selection.odds,
        banker: selection.banker || false,
        game: selection.game,
        eventMarketOutcomes: [...selection.markets].map(([, outcome]) => {
          return {
            eventId: selection.eventId,
            eventMarketId: outcome.marketId,
            eventMarketOutcomeId: outcome.outcomeId,
            odds: outcome.odds,
          };
        }),
      });
      return acc;
    }, []),
  }));
};

const formatMultipleBets = (rawSelections, betMultiples) => {
  return [...betMultiples].reduce((acc, [, multiple]) => {
    acc.push({
      type: 'OPEN',
      selections: rawSelections.reduce((acc, n) => {
        if (!n.active) return acc;

        acc.push({
          type: 'REGULAR',
          odds: n.odds,
          banker: false,
          game: n.game,
          eventMarketOutcomes: [...n.markets].map(([, outcome]) => ({
            eventId: n.eventId,
            eventMarketId: outcome.marketId,
            eventMarketOutcomeId: outcome.outcomeId,
            odds: outcome.odds,
          })),
        });

        return acc;
      }, []),
      selectedSystems: multiple.systems,
      payin: {
        stake: {
          type: 'TOTAL',
          amount: parseFloat(multiple.payment).toFixed(2),
        },
      },
    });

    return acc;
  }, []);
};

export function formatBetForBetslipPlacement({
  selectionsData,
  stake,
  systems,
  selectedTicketType,
  betMultiples,
}) {
  const selections = Object.values(selectionsData ?? {});
  if (!selections.length) return {};

  const betslip = {
    reqUuid: uuidv4(),
    currency: window.currency,
    language: window.languageCode,
  };

  switch (selectedTicketType) {
    case 'combo':
      betslip.bets = formatComboBets(selections, stake);
      break;
    case 'single':
      betslip.bets = formatSingleBets(selections, stake);
      break;
    case 'system':
      betslip.bets = formatSystemBets(selections, stake, systems);
      break;
    case 'multiples':
      betslip.bets = formatMultipleBets(selections, betMultiples);
      break;
    default:
      betslip.bets = formatComboBets(selections, stake);
  }

  return betslip;
}

export function formatThirdPartyBet(bet, selections) {
  return {
    selections,
    type: bet.betslipType,
    payin: { total: bet.stake },
  };
}

export function formatThirdPartySelection({
  category,
  selection,
  tournament,
  marketName,
  outcomeName,
}) {
  return {
    event: { syntheticId: selection.eventId },
    eventMarketOutcomes: [
      {
        syntheticId: selection.eventMarketOutcomeId,
        name: outcomeName,
        eventMarket: { syntheticId: selection.eventMarketId, name: marketName },
      },
    ],
    type: 'REGULAR', // used hardcoded value for now
    banker: false, // used hardcoded value for now
    sport: { syntheticId: category.sportId },
    category: { syntheticId: tournament.categoryId },
    tournament: { syntheticId: tournament.id },
  };
}
