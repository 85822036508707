import { useStore } from '@/stores/store';
import { getFavouritesOffer } from '@/api/distribution';
import { constructEndDate, constructStartDate } from '@/utils/helpers';
import { constructClientIds } from '../utils/helpers';

/**
 * Loads favourites cursor offer.
 * @async
 * @function loadFavouritesOffer
 * @returns {Promise<void>} A promise that resolves when the favourites offer is successfully loaded and stored.
 */
export async function loadFavouritesOffer() {
  const store = useStore();

  try {
    const selectedFiltersExternal = store.selectedFiltersExternal;
    const params = {
      events: Object.keys(store.punterPreferences?.favouriteEvents ?? {}),
      tournaments: Object.keys(store.punterPreferences?.favouriteTournaments ?? {}),
      includeEventCountStats: true,
      type: selectedFiltersExternal.sortBy === 'byTime' ? 2 : 1,
      numberOfMarketsPerSport: 7,
      clientIds: constructClientIds(),
      expectedNumberOfEvents: 100,
      ...(store.config?.supplemental?.list
        ? { supplementalNameTypeIds: store.config.supplemental.list }
        : {}),
    };

    if (selectedFiltersExternal?.time !== 'all') {
      const startDate =
        store.timeFilters[selectedFiltersExternal.time]?.date ?? constructStartDate();

      const endDate =
        store.timeFilters[selectedFiltersExternal.time]?.endDate ??
        constructEndDate(startDate) ??
        constructEndDate();

      params.startsAtFrom = startDate;
      params.startsAtTo = endDate;
    }

    const response = await getFavouritesOffer(params, store.isLive);

    store.useOfferAdapter('INITIAL_OFFER_SETUP', response.data);
    store.getTournamentGroupingConfigs(
      store.isLive,
      response.data.tournaments.map(({ id }) => id),
    );
    store.setNumberOfFavouriteEvents(Object.values(response.data?.sportNumberOfEvents ?? {}));

    store.setLoading(false);
  } catch (error) {
    console.log(`We've encountered error loading favourites offer.`, error);
  }
}

/**
 * Function dedicated to fetch and store number of favourite events.
 * @async
 * @function fetchAndCalculateNumberOfFavouriteEvents
 * @returns {Promise<void>} A promise that resolves once number of favourite events is fetched and stored.
 */
export async function fetchAndCalculateNumberOfFavouriteEvents() {
  const store = useStore();

  try {
    const selectedFiltersExternal = store.selectedFiltersExternal;
    const params = {
      expectedNumberOfEvents: 0,
      numberOfMarketsPerSport: 0,
      excludeSportCategoryTournamentMetadata: true,
      includeEventCountStats: true,
      events: Object.keys(store.punterPreferences?.favouriteEvents ?? {}),
      tournaments: Object.keys(store.punterPreferences?.favouriteTournaments ?? {}),
      clientIds: constructClientIds(),
    };

    if (selectedFiltersExternal?.time !== 'all') {
      const startDate =
        store.timeFilters[selectedFiltersExternal.time]?.date ?? constructStartDate();

      const endDate =
        store.timeFilters[selectedFiltersExternal.time]?.endDate ??
        constructEndDate(startDate) ??
        constructEndDate();

      params.startsAtFrom = startDate;
      params.startsAtTo = endDate;
    }

    const response = await getFavouritesOffer(params, store.isLive);
    store.setNumberOfFavouriteEvents(Object.values(response.data?.sportNumberOfEvents ?? {}));
  } catch (error) {
    console.log(`We've encountered error loading number of favourite events.`, error);
  }
}
