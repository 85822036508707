const statusMap = {
  1: 'NS',
  2: '1I.H',
  3: '1I.A',
  4: '2I.H',
  5: '2I.A',
  6: '3I.H',
  7: '3I.A',
  8: '4I.H',
  9: '4I.A',
  10: 'END',
  11: 'A.SO',
  12: 'SO.H',
  13: 'SO.A',
  14: 'END.S',
  15: 'I.BREAK',
  16: 'S.BREAK',
  17: 'L.BREAK',
  18: 'T.BREAK',
  19: 'STUMPS',
  20: 'D.BREAK',
  21: 'DRINKS',
};

const scorePeriodsMap = {
  1: '1I',
  2: '2I',
  3: '3I',
  4: '4I',
};

function mapPeriods(periods = []) {
  return periods.reduce((score, { id, value: n }) => {
    score.push({ label: scorePeriodsMap[id], scores: [n.home, n.away] });
    return score;
  }, []);
}

export function mapCricketEvent(event) {
  const dismissals = event.events.find((event) => event.id === 5)?.value ?? {};
  const gameScore = event.events.find((event) => event.id === 1)?.value ?? { home: 0, away: 0 };

  const currentGameScore = {
    home: `${gameScore.home ?? 0}/${dismissals?.home ?? 0}`,
    away: `${gameScore.away ?? 0}/${dismissals?.away ?? 0}`,
  };

  return {
    displayEventStatus: true,
    eventStatus: statusMap[event.eventStatusId],
    eventStatusId: event.eventStatusId,
    periods: mapPeriods(event.events.find((event) => event.id === 2)?.periods),
    currentGameScore,
    compactModeScores: [currentGameScore],
  };
}
