<script setup>
import {
  computed,
  defineAsyncComponent,
  onBeforeMount,
  onBeforeUnmount,
  toRef,
  watch,
  nextTick,
  ref,
} from 'vue';
import { orderBy } from 'lodash-es';
import { SpecifierTypesCache } from '@nsftx/sports-client-aio-display-name-sdk';

import { useStore } from '@/stores/store';
import { useBetslipStore } from '@/stores/BetslipStore';

import { getPunterPreferences } from '@/api/punter-preferences';
import getLocalConfig from '@/api/local-config';
import getTranslations from '@/api/translations';

import {
  establishBetslipDistributionSocketConnection,
  disconnectFromBetslipDistributionSocketConnection,
} from '@/services/sockets/betslipDistributionSocket';
import { establishCalculationSocketConnection } from '@/services/sockets/calculationSocket';
import { initializeOfferDistributionWSConnection } from '@/services/sockets/offer-distribution';

import { isMobile } from '@/services/helpers';
import {
  getCursorOfferStats,
  getCursorPromoOfferStats,
  getMarketSpecifiers,
  getOffer,
  getPromotedOffer,
} from '@/api/distribution';
import { loadFavouritesOffer, fetchAndCalculateNumberOfFavouriteEvents } from '@/adapters/offer';
import {
  constructClientIds,
  constructEndDate,
  constructStartDate,
  convertToIntegerOdds,
  normalizeAndRemoveDiacritics,
} from '@/utils/helpers';
import tenants from '@/utils/tenants';
import { getItemFromStorage, removeItemFromStorage, setItemToStorage } from '@/services/storage';
import { resetLocalState } from '@/services/offer-adapter';
import { establishCashoutSocketConnection } from '@/services/sockets/cashoutSocket';
import loadSportradarTheming from '@/utils/loadSRTheming.js';
import { sirConnect } from '@/services/sirWidgetLoader';
import createChildIntegratorInstance from '@/services/child-integrator.js';
import { initGoogleTagManager } from '@/services/googleTagManager';
import useBetslip from '@/composables/betslip/useBetslip';
import { useRoute } from 'vue-router';

const store = useStore();
const { setSelectedFiltersExternal } = store;
const betslipStore = useBetslipStore();
const { calculatePayments } = useBetslip();

let initialLoad = true;

const route = useRoute();

const isLive = computed(() => store.isLive);
const game = computed(() => route?.name?.toUpperCase());

/**
 * Retrieves local app config (default and per-tenant config, if tenant name provided) and stores it.
 * @async
 * @function loadAppConfig
 * @returns {Promise<Boolean>}
 */
async function loadAppConfig() {
  const tenantName =
    new URLSearchParams(document.location.search).get('tenantName') ??
    tenants[window.tenantUuid]?.toLowerCase();

  const cfg = await getLocalConfig(tenantName);
  store.setConfig(cfg);

  return true;
}

/**
 * Retrieves punter preferences from the server and stores them.
 * @async
 * @function applyPunterPreferences
 * @returns {Promise<void>} A promise that resolves when the punter preferences are retrieved and stored successfully.
 */
async function applyPunterPreferences() {
  const { config } = store;

  const localPunterPreferences = getItemFromStorage('punterPreferences') || {};
  const data = window.punterToken
    ? ((await getPunterPreferences(window.punter?.id)) ?? localPunterPreferences)
    : localPunterPreferences;

  const validatePreference = (key, validSet, set = config) => {
    if (data?.[key] && !set?.[validSet]?.has(data[key])) delete data[key];
  };

  validatePreference(
    'offerView',
    isMobile ? 'availableMobileOfferViews' : 'availableDesktopOfferViews',
  );
  validatePreference('oddsFormat', 'availableOddFormats');
  validatePreference('theme', 'availableThemes');
  validatePreference('oddsChangesOption', 'availableOddsChangesOptions', config?.betslip);

  store.punterPreferences = data;

  if (
    store.config[isMobile ? 'defaultMobileOfferView' : 'defaultDesktopOfferView'] ===
      'indonesian' &&
    !betslipStore.indonesianOfferType
  ) {
    betslipStore.setIndonesianOfferType(data.indonesianOfferType || 'single');
  }

  const localStorageTheme = getItemFromStorage('punter-theme');
  if (!localStorageTheme && data.theme) {
    setItemToStorage('punter-themes', data.theme);
  }

  setupTheming();

  loadFavouritesData();
}

/**
 * Loads the theme based on the provided query parameters and sets the company name if available.
 * @function setupTheming
 * @returns {void}
 */
function setupTheming() {
  window.tenantName =
    new URLSearchParams(document.location.search).get('tenantName') ??
    tenants[window.tenantUuid]?.toLowerCase();

  const punterTheme = getItemFromStorage('punter-theme') ?? store.punterPreferences?.theme;
  const selectedTheme = window.theme || punterTheme;

  const theme = store.config.availableThemes?.has(selectedTheme)
    ? selectedTheme
    : store.config.defaultTheme;
  store.punterPreferences.theme = theme;

  document.documentElement.dataset.theme = theme;
  document.documentElement.dataset.company = tenantName || '';

  import(`@/assets/styles/themes/_${tenantName}.scss`).catch(() => {
    /* Do nothing */
  });

  loadSportradarTheming(theme);
}

/**
 * Prepares offer statistics requests based on selected filters and updates the store with the fetched data.
 * @function getStatsRequestsWithParams
 * @returns {Array<Promise<void>>} An array of promises that resolves when the offer statistics are successfully loaded and stored.
 */

function getStatsRequestsWithParams() {
  const selectedFiltersExternal = store.selectedFiltersExternal;

  const params = {
    clientIds: constructClientIds(),
    ...(store.config?.supplemental?.list
      ? { supplementalNameTypeIds: store.config.supplemental.list }
      : {}),
  };

  if (selectedFiltersExternal?.time !== 'all' && store.selectedView !== 'outrights') {
    const startDate = store.timeFilters[selectedFiltersExternal.time]?.date ?? constructStartDate();

    const endDate =
      store.timeFilters[selectedFiltersExternal.time]?.endDate ??
      constructEndDate(startDate) ??
      constructEndDate();

    params.startsAtFrom = startDate;
    params.startsAtTo = endDate;
  }

  if (isLive.value) params.streamContentTypes = [1];

  const requests = [];
  requests.push(
    getCursorOfferStats(
      { ...params, eventCounterType: 2 },
      game.value === 'LIVE' ? '2' : selectedFiltersExternal.sortBy !== 'byTime' ? '1,2' : '1',
    ),
  );

  if (store.config.promotedOffer) {
    requests.push(getCursorPromoOfferStats(params, game.value === 'LIVE' ? '2' : '1'));
  }

  return requests;
}

/**
 * Loads favourites data (offer or stats).
 * @function loadFavouritesData
 * @returns {Promise<void>}
 */
function loadFavouritesData() {
  if (store.selectedFiltersExternal?.favourites) loadAndSubscribeOfferDataByFilters();
  else fetchAndCalculateNumberOfFavouriteEvents();
}

/**
 * Creates object to be passed to selected filters object in store, or to be passed in request for filtering
 * @function createFilterParams
 * @returns {Object}
 */
function createFilterParams() {
  const params = {};
  const { selectedFiltersExternal } = store;

  if (selectedFiltersExternal.sport || routeParams.value.sport)
    params.sports =
      selectedFiltersExternal.sport?.id ?? selectedFiltersExternal.sport ?? routeParams.value.sport;

  if (selectedFiltersExternal.category || routeParams.value.category)
    params.categories =
      selectedFiltersExternal.category?.id ??
      selectedFiltersExternal.category ??
      routeParams.value.category;

  if (selectedFiltersExternal.tournament || routeParams.value.tournament)
    params.tournaments =
      selectedFiltersExternal.tournament?.id ??
      selectedFiltersExternal.tournament ??
      routeParams.value.tournament;

  if (selectedFiltersExternal.promotedOffer || routeParams.value.promotedOffer)
    params.promotedOffers =
      selectedFiltersExternal.promotedOffer?.id ??
      selectedFiltersExternal.promotedOffer ??
      routeParams.value.promotedOffer;

  if (selectedFiltersExternal.catalog || routeParams.value.catalog)
    params.promotedOfferCatalogs =
      selectedFiltersExternal.catalog?.id ??
      selectedFiltersExternal.catalog ??
      routeParams.value.catalog;

  return params;
}

/**
 * Get cursor offer request based on selected filters
 * @async
 * @function getOfferRequestWithParams
 * @returns {Promise<void>} A promise of promoted or regular request
 */
function getOfferRequestWithParams(forceRegularOffer = false) {
  const selectedFiltersExternal = store.selectedFiltersExternal;

  const params = {
    type: selectedFiltersExternal.sortBy === 'byTime' ? 2 : 1,
    clientIds: constructClientIds(),
    ...(store.config?.supplemental?.list
      ? { supplementalNameTypeIds: store.config.supplemental.list }
      : {}),
    ...createFilterParams(),
  };

  if (selectedFiltersExternal?.time !== 'all') {
    const startDate = store.timeFilters[selectedFiltersExternal.time]?.date ?? constructStartDate();

    const endDate =
      store.timeFilters[selectedFiltersExternal.time]?.endDate ??
      constructEndDate(startDate) ??
      constructEndDate();

    params.startsAtFrom = startDate;
    params.startsAtTo = endDate;
  }

  if (selectedFiltersExternal.streamedEvents || routeParams.value.streamedEvents) {
    params.streamContentTypes = [1];
  }

  if (routeParams.value.view !== 'search') {
    store.cursorId = null;

    if (routeParams.value.favourites) {
      return loadFavouritesOffer();
    }

    if (
      !forceRegularOffer &&
      (params.promotedOffers ||
        params.promotedOfferCatalogs ||
        (store.config?.defaultRoute[isLive.value ? 'live' : 'prematch'].offer === 'promo' &&
          initialLoad &&
          !params.sports &&
          !params.categories &&
          !params.tournaments &&
          !routeParams.value.favourites &&
          !routeParams.value.streamedEvents))
    ) {
      if (params.promotedOffers && params.promotedOffers === 'all') {
        delete params.promotedOffers;
      }

      return getPromotedOffer(params, game.value === 'LIVE');
    } else {
      return getOffer(params, game.value === 'LIVE');
    }
  }
}

/**
 * Fetches offer-related data based on selected filters and subscribes to offer distribution socket events.
 * @async
 * @function loadAndSubscribeOfferDataByFilters
 * @returns {Promise<void>} A promise that resolves when the offer data fetching and subscription are complete.
 */
async function loadAndSubscribeOfferDataByFilters() {
  if (store.selectedView === 'outrights') return;

  if (store.selectedFiltersExternal?.favourites) {
    await loadFavouritesOffer();
  } else {
    try {
      store.setLoading(true);
      const response = await getOfferRequestWithParams();

      store.useOfferAdapter('INITIAL_OFFER_SETUP', response.data);
      store.getTournamentGroupingConfigs(
        game.value === 'LIVE',
        response.data.tournaments.map(({ id }) => id),
      );
    } catch (error) {
      console.error(`We've encountered error loading offer.`, error);
    } finally {
      store.setLoading(false);
    }
  }
}

/**
 * Function to set offer stats data inside the store
 * @function setOfferStatsData
 * @returns {Object} Object with formatted promo filter data
 */
function setOfferStatsData(data) {
  if (Object.values(store.filterSports).length) {
    store.resetFilterData();
  }

  const { filterSports, filterCategories, filterTournaments } = store.setFilterData(data);

  if (initialLoad) store.setOutrightsData(data);

  if (data.sportNumberOfStreamEvents)
    store.setNumberOfStreamedEvents(data.sportNumberOfStreamEvents);

  return {
    filterSports,
    filterCategories,
    filterTournaments,
  };
}

/**
 * Function to set promo stats data inside the store
 * @function setPromoStatsData
 * @returns {Object} Object with formatted promo filter data
 */
function setPromoStatsData(response) {
  if (response?.status !== 200 || !response?.data) {
    return {
      promoFilterOffers: {},
      promoFilterCatalogs: {},
    };
  }

  const { data: promoStatsData } = response;

  if (Object.values(store.promoFilterOffers).length) {
    store.resetPromoOfferFilterData();
  }

  const { promoFilterOffers, promoFilterCatalogs } = store.setPromoOfferFilterData(promoStatsData);

  return {
    promoFilterOffers,
    promoFilterCatalogs,
  };
}

/**
 * Reusable function which is used on application initial load, and during the game switch.
 * Function initializes Offer Distribution WS connection, resets previous filter and offer data and loads new one.
 * @async
 * @function loadInitialAppData
 * @returns {Promise<void>} A promise that resolves once necessary data is loaded.
 */
async function loadInitialAppData() {
  store.setLoading(true);
  initializeOfferDistributionWSConnection(game.value === 'LIVE' ? '2' : '1,2', {
    ...(store.config?.supplemental?.list
      ? { supplementalNameTypeIds: store.config.supplemental.list }
      : {}),
    ...(store.config.promotedOffer ? { extensions: 'promoted-offer' } : {}),
  });

  if (route.name === 'home') {
    store.setLoading(false);
    return;
  }

  Promise.all(getStatsRequestsWithParams())
    .then((responses) => {
      const [cursorOfferStatsResponse, promoOfferStatsResponse] = responses;

      if (cursorOfferStatsResponse.status !== 200 || !cursorOfferStatsResponse.data) return;

      const { data: cursorData } = cursorOfferStatsResponse;
      const { filterSports, filterCategories, filterTournaments } = setOfferStatsData(cursorData);
      const { promoFilterOffers, promoFilterCatalogs } = setPromoStatsData(promoOfferStatsResponse);

      const { selectedFiltersExternal } = store;
      const params = createFilterParams();

      const filtersToUpdate = {
        ...store.selectedFiltersExternal,
        ...(params.sports ? { sport: filterSports[params.sports] ?? params.sports } : {}),
        ...(params.categories
          ? { category: filterCategories[params.categories] ?? params.categories }
          : {}),
        ...(params.tournaments
          ? { tournament: filterTournaments[params.tournaments] ?? params.tournaments }
          : {}),
        ...(params.promotedOffers && Object.values(promoFilterOffers).length
          ? {
              promotedOffer: promoFilterOffers[params.promotedOffers],
            }
          : {}),
        ...(params.promotedOfferCatalogs && Object.values(promoFilterCatalogs).length
          ? {
              catalog: promoFilterCatalogs[params.promotedOfferCatalogs],
              promotedOffer:
                promoFilterOffers[
                  promoFilterCatalogs[params.promotedOfferCatalogs]?.promotedOfferId
                ],
            }
          : {}),
        favourites: routeParams.value.favourites ?? null,
        streamedEvents: routeParams.value.streamedEvents ?? null,
      };

      if (params.categories || params.tournaments) {
        store.expandedSport = params.tournaments
          ? filterCategories[filterTournaments[params.tournaments]?.categoryId]?.sportId
          : filterCategories[params.categories]?.sportId;
      }

      if (params.tournaments) {
        store.expandedCategory = filterTournaments[params.tournaments]?.categoryId;
      }

      // in case there is no parameters in url and is initial load based on default selection
      // from config load first sport or promo offer
      if (
        initialLoad &&
        !filtersToUpdate?.streamedEvents &&
        !selectedFiltersExternal?.streamedEvents &&
        !params?.sports &&
        !params?.categories &&
        !params?.tournaments &&
        !params?.promotedOffers &&
        !params?.promotedOfferCatalogs
      ) {
        const firstSport = orderBy(
          Object.values(filterSports).filter((sport) => sport.numberOfEvents),
          [
            game.value === 'LIVE' ? 'positionLive' : 'positionPrematch',
            ({ name }) => normalizeAndRemoveDiacritics(name),
          ],
        )?.[0];

        const setFirstSport = () => {
          if (!firstSport || store.selectedView === 'outrights') return;
          params.sports = firstSport.id;
          filtersToUpdate.sport = firstSport;
        };

        switch (store.config?.defaultRoute[isLive.value ? 'live' : 'prematch'].offer) {
          case 'sport':
            if (!isLive.value) {
              setFirstSport();
            }
            break;

          case 'promo': {
            const firstOffer = orderBy(promoFilterOffers, 'position')?.[0];

            if (firstOffer) {
              if (store.selectedView !== 'outrights') {
                filtersToUpdate.promotedOffer = firstOffer;
                filtersToUpdate.id = null;
              }
              store.expandedPromoOffer = firstOffer.id;
            } else {
              if (!isLive.value) {
                setFirstSport();
              } else {
                filtersToUpdate.id = 'all';
              }
            }
          }
        }
      }
      setSelectedFiltersExternal(filtersToUpdate);
    })
    .then(async () => {
      const initialData = (await getOfferRequestWithParams()).data;
      const shouldReFetchOffer =
        'promotedOffers' in initialData && !initialData.promotedOffers?.length;

      const finalData = shouldReFetchOffer
        ? (await getOfferRequestWithParams(true)).data
        : initialData;

      store.useOfferAdapter('INITIAL_OFFER_SETUP', finalData);
      store.getTournamentGroupingConfigs(
        game.value === 'LIVE',
        finalData.tournaments.map(({ id }) => id),
      );
    })
    .catch((error) => {
      console.error(`We've encountered error while loading offer data.`, error);
    })
    .finally(() => {
      store.setLoading(false);
      if (initialLoad) initialLoad = false;
    });

  betslipStore.subscribeBetslipEvents();

  if (!store.selectedFiltersExternal.favourites) fetchAndCalculateNumberOfFavouriteEvents();
}

/**
 * Fetches market specifiers used in Expresion language.
 * @async
 * @function loadMarketSpecifiers
 * @returns {Promise<void>} A promise that resolves once market specifiers are loaded and stored.
 */
async function loadMarketSpecifiers() {
  try {
    const response = await getMarketSpecifiers();
    new SpecifierTypesCache().setSpecifierTypes(response.data.types);
  } catch (error) {
    console.log(`We've encountered error loading market specifiers.`, error);
  }
}

let appReadyForLoad = ref(false);
async function loadApp({ loadSpecifiers = true } = {}) {
  store.currency = window.currency;
  store.setLoading(true);
  establishCalculationSocketConnection();

  if (loadSpecifiers) loadMarketSpecifiers();

  await loadAppConfig();
  appReadyForLoad.value = true;

  let time = 'all';

  if (!isLive.value) {
    time = store.routeParams.time ?? store.config?.defaultRoute.prematch.time ?? 'all';
  }

  store.setSelectedFiltersExternal({
    ...store.selectedFiltersExternal,
    time,
  });

  applyPunterPreferences();
  loadInitialAppData();

  getTranslations()
    .then((translations) => {
      store.setTranslations(translations);
    })
    .catch((error) => {
      console.error(`Error while fetching translations.`, error);
    });

  store.createLiveEventsCountPeriodicUpdate();

  betslipStore.sendBetslipWorkerMessage('generate_pascal_triangle', {
    maxSelections: 30,
  });

  betslipStore.sendBetslipWorkerMessage('setup-limits-environment', {
    currency: window.currency,
    tenantUuid: window.tenantUuid,
    language: window.languageCode,
  });

  for (let i = 0; i < store.config.betslip.numberOfBetslips; i++) {
    const id = `betslip-${i}`;
    const storedBet = getItemFromStorage(id);

    if (storedBet?.selections.length) {
      store.sendOfferWorkerMessage(
        'load-stored-bet',
        {
          bet: storedBet,
          tenantUuid: window.tenantUuid,
          languageCode: window.languageCode,
          playerUuid: store.route.query?.playerUuid,
          ...(store.config?.supplemental?.list
            ? { supplementalNameTypeIds: store.config.supplemental.list }
            : {}),
        },
        id,
      );
    } else {
      betslipStore.createBetslip(id);
    }
  }

  const { srWidgets = {} } = store.config;
  if (srWidgets?.liveMatchTracker?.enabled || srWidgets?.betAssist?.enabled) {
    sirConnect(store.config?.srWidgets?.url);
  }

  if (store.config.googleTagManager?.enabled) {
    initGoogleTagManager(store.config.googleTagManager.id);
  }

  try {
    await import(`../../node_modules/dayjs/locale/${window.languageCode}.js`);
    dayjs.locale(window.languageCode);
  } catch (e) {
    console.log(
      `An error occured while loading dayjs locale ${window.languageCode}, app will use en locale for date formats.`,
      e,
    );
  }

  // Notify parent and product is completely loaded.
  integrator.loaded();
}

emitter.on('load-app', (data) => loadApp(data));
onBeforeMount(() => {
  if (window.standalone) {
    store.sendOfferWorkerMessage('set-worker-environment', {
      location: window.location,
    });
    loadApp();
  }
});

let shouldLoadPunterPreferences = false;
const integrator = createChildIntegratorInstance({
  onLoad: async ({
    context,
    settings,
    punter = {},
    activeCurrencyBalance = 0,
    gameOptions = {},
  }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const languageParam = urlParams.get('language');
    const localeParam = urlParams.get('locale');

    window.tenantUuid = context.tenantId;
    window.languageCode = settings.activeLocale ?? languageParam ?? localeParam ?? 'en';
    window.currency = settings.activeCurrency.code;
    window.oddsType = settings?.oddsOption?.toLowerCase() ?? '';
    window.manualWalletsUpdate = settings?.manualWalletsUpdate ?? false;

    if (punter?.identity?.id) {
      window.punter = { id: punter.identity.id };
      store.punterData = {
        nickname: punter.details?.nickname,
        id: punter.identity.id,
        type: punter.identity.type,
      };
    }

    if (window.punter?.id && +activeCurrencyBalance) {
      store.updatePunterWallet({
        enabled: true,
        activeCurrencyBalance: activeCurrencyBalance,
      });
    }

    if (gameOptions.betslip) {
      await loadMarketSpecifiers();
      betslipStore.handleThirdPartyBetsPlacement(gameOptions.betslip);
    }

    loadApp({ loadSpecifiers: !gameOptions.betslip });
  },

  onPunterDetailsUpdated: (data) => {
    const reloadAppData = data.id !== window?.punter?.id;

    window.punter = data;

    store.punterData = data;
    shouldLoadPunterPreferences = true;

    if (reloadAppData && !store.selectedFiltersExternal.favourites) {
      fetchAndCalculateNumberOfFavouriteEvents();
    }
  },

  onPunterSessionUpdated: (data) => {
    window.punterToken = data.punterToken;
    store.punterLoggedIn = !!window.punterToken;

    if (punterToken) {
      establishBetslipDistributionSocketConnection(window.punter);
      establishCashoutSocketConnection(window.punter);
      if (window.manualWalletsUpdate) autoReloadWallets();
      store.loadPunterBets();
    } else disconnectFromBetslipDistributionSocketConnection();

    if (shouldLoadPunterPreferences) {
      shouldLoadPunterPreferences = false;
      applyPunterPreferences();

      emitter.emit('punter-preferences-changed');
    }

    emitter.emit('session-updated');
  },

  onPunterSessionDestroyed: () => {
    window.punter = null;
    window.punterToken = null;

    store.punterLoggedIn = false;
    store.punterData = null;
  },

  onPunterWalletsUpdated: (data) => {
    console.log('[AIO Log] Punter.Wallets message received from Integrator:', data);

    if (data?.activeCurrencyBalance) {
      store.updatePunterWallet({
        enabled: true,
        activeCurrencyBalance: data.activeCurrencyBalance,
      });
    }
  },

  onSettingsUpdated: (data) => {
    if (!data) return;
    const settingsCurrency = data.activeCurrency?.code;
    const settingsLocale = data.activeLocale;
    const settingsOdds = data.oddsOption;

    if (settingsCurrency && typeof settingsCurrency === 'string') {
      window.currency = settingsCurrency.toUpperCase();
      store.currency = window.currency;
      betslipStore.requestBetCalculation();
    }

    if (settingsLocale) {
      window.languageCode = settingsLocale;
      getTranslations().then((translations) => store.setTranslations(translations));
    }

    if (settingsOdds && store.config.availableOddFormats.has(settingsOdds)) {
      store.customOddFormat = settingsOdds;
      store.updatePunterPreferences(
        {
          key: 'oddFormat',
          value: settingsOdds,
        },
        true,
      );
    }
  },
});

emitter.on('require-login', () => integrator && integrator.requireLogin());
emitter.on('navigate-to', (data) => integrator && integrator.navigateTo(data));

emitter.on('fetch-wallet', () => integrator && window.manualWalletsUpdate && loadWallets());

emitter.on('send-scroll-position', (payload) => integrator && integrator.sendDomEvent(payload));

async function loadWallets() {
  try {
    const wallets = await integrator.getWallets();
    store.updatePunterWallet({
      enabled: true,
      activeCurrencyBalance: wallets?.activeCurrencyBalance,
    });
  } catch (error) {
    console.log('Error fetching wallets.', error);
  }
}

let autoReloadWalletsInterval = null;
function autoReloadWallets() {
  clearInterval(autoReloadWalletsInterval);
  autoReloadWalletsInterval = setInterval(loadWallets, 60000);
}

watch(game, async (newVal) => {
  resetLocalState();
  store.resetCompleteOfferState();
  initialLoad = true;
  window.scrollTo({ top: 0, behavior: 'instant' });

  const metaSelected =
    routeParams.value.streamedEvents ||
    routeParams.value.sport ||
    routeParams.value.category ||
    routeParams.value.tournament ||
    routeParams.value.promotedOffer ||
    routeParams.value.catalog;

  if (newVal === 'LIVE') {
    setSelectedFiltersExternal({
      ...store.selectedFiltersExternal,
      id: metaSelected ? '' : 'all',
      time: 'all',
      sport: routeParams.value.sport ?? null,
      category: routeParams.value.category ?? null,
      tournament: routeParams.value.tournament ?? null,
      promotedOffer: routeParams.value.promotedOffer ?? null,
      catalog: routeParams.value.catalog ?? null,
      favourites: false,
      streamedEvents: false,
      sortBy: 'byTournament',
    });
    store.setSelectedView('live');
  } else if (newVal === 'SPORTS') {
    setSelectedFiltersExternal({
      offer: 'offer',
      sport: routeParams.value.sport ?? null,
      category: routeParams.value.category ?? null,
      tournament: routeParams.value.tournament ?? null,
      promotedOffer: routeParams.value.promotedOffer ?? null,
      catalog: routeParams.value.catalog ?? null,
      time: routeParams.value.time ?? store.config?.defaultRoute.prematch.time ?? 'all',
      favourites: false,
      sortBy: 'byTournament',
    });
    store.setSelectedView('sports');
  }

  store.setSearchTerm('');

  loadInitialAppData();
});

const selectionsToWatch = computed(() => {
  const betslip = betslipStore.getCurrentBetslip();
  if (!betslip) return [];

  return betslip.selectionsList.reduce((selectionArr, selectionId) => {
    const selection = betslip.selectionsData[selectionId];

    return selection.active
      ? [
          ...selectionArr,
          {
            odds: selection.odds,
            numOfMarkets: selection.markets.size,
            betslipId: `betslip-${betslipStore.selectedBetslipIndex}`,
          },
        ]
      : selectionArr;
  }, []);
});

watch(
  [
    selectionsToWatch,
    toRef(() => {
      const betslip = betslipStore.getCurrentBetslip();
      return betslip?.stake;
    }),
    toRef(() => betslipStore.betMultiples),
  ],
  ([newSelections, newStake], [oldSelections, oldStake]) => {
    const [firstNewSelection] = newSelections;
    const [firstOldSelection] = oldSelections;

    if (firstNewSelection?.betslipId !== firstOldSelection?.betslipId) {
      betslipStore.calculationWinnings = null;
    }

    if (
      betslipStore.selectedTicketType === 'single' &&
      store.offerView === 'indonesian' &&
      oldSelections?.length
    ) {
      const newOdds = convertToIntegerOdds(firstNewSelection?.odds ?? 0, 10000);
      const oldOdds = convertToIntegerOdds(firstOldSelection?.odds ?? 0, 10000);

      const betslip = betslipStore.getCurrentBetslip();
      if (
        (((newOdds > 2 && oldOdds > 0 && oldOdds <= 2) ||
          (newOdds > 0 && newOdds <= 2 && oldOdds > 2)) &&
          !store.isRebetSelected) ||
        (store.isRebetSelected && newOdds > 2) ||
        (!firstOldSelection?.odds && newOdds <= 2)
      ) {
        betslipStore.calculationWinnings = null;
        betslipStore.updateDesiredProfit(
          firstNewSelection?.betslipId === firstOldSelection?.betslipId
            ? null
            : betslip?.desiredProfit,
        );
        betslipStore.updateStake(newOdds > 2 ? betslipStore.defaultStake : 0);
        calculatePayments();
      }
    }

    betslipStore.requestBetCalculation();

    if (store.isRebetSelected) {
      store.isRebetSelected = false;
    }

    if (!newSelections.length && !oldSelections.length) return;
    if (!newSelections.length) {
      removeItemFromStorage(`betslip-${betslipStore.selectedBetslipIndex}`);
      return;
    }

    if (JSON.stringify(newSelections) !== JSON.stringify(oldSelections) || newStake !== oldStake)
      betslipStore.storeBetslipDataInLS();
  },
  { deep: true },
);

const routeParams = computed(() => store.routeParams);
watch(routeParams, (params, oldParams) => {
  // handle eventview changes
  if (params.event && oldParams.event !== params.event) {
    store.eventviewId = +params.event;
  } else if (!params.event && oldParams.event) {
    store.eventviewId = null;
  }

  // handle expanded category or sport on menu
  if (!params.tournament && !params.category && (oldParams.tournament || oldParams.category)) {
    store.expandedSport = null;
  }

  if (!params.tournament && oldParams.tournament) {
    store.expandedCategory = null;
  }

  if (isMobile || params.view === 'outrights' || oldParams.view === 'outrights') {
    if (params.view) store.setSelectedView(params.view);
    else store.setSelectedView(route.name);
  }

  if (initialLoad) return;

  const areFiltersDifferent =
    params.sport !== oldParams.sport ||
    params.category !== oldParams.category ||
    params.tournament !== oldParams.tournament ||
    params.promotedOffer !== oldParams.promotedOffer ||
    params.catalog !== oldParams.catalog ||
    params.time !== oldParams.time ||
    params.favourites !== oldParams.favourites ||
    params.streamedEvents !== oldParams.streamedEvents;

  // handle filter changes
  if (areFiltersDifferent || params.view === 'outrights' || oldParams.view === 'outrights') {
    const id =
      isLive.value &&
      !params.sport &&
      !params.favourites &&
      !params.streamedEvents &&
      !params.promotedOffer &&
      !params.catalog
        ? 'all'
        : null;

    setSelectedFiltersExternal({
      ...store.selectedFiltersExternal,
      ...params,
      time: params.time ?? Object.values(store.timeFilters)[0].id,
      sport: params.sport,
      category: store.filterCategories[params.category] ?? params.category,
      tournament: store.filterTournaments[params.tournament] ?? params.tournament,
      promotedOffer:
        store.promoFilterOffers[params.promotedOffers] ??
        params.promotedOffer ??
        (params.catalog
          ? store.promoFilterOffers[store.promoFilterCatalogs[params.catalog]?.promotedOfferId]
          : null) ??
        (!params.sport &&
        !params.category &&
        !params.tournament &&
        !params.favourites &&
        !params.streamedEvents &&
        !params.view &&
        id !== 'all' &&
        store.config?.defaultRoute[isLive.value ? 'live' : 'prematch'].offer === 'promo'
          ? Object.values(store.promoFilterOffers)[0]
          : null),
      catalog: store.promoFilterCatalogs[params.catalog] ?? params.catalog,
      favourites: params.favourites ?? null,
      streamedEvents: params.streamedEvents ?? null,
      id,
    });
  }

  nextTick(async () => {
    if ((areFiltersDifferent || oldParams.view === 'outrights') && params.view !== 'search') {
      if (params.time !== oldParams.time) {
        Promise.all([getOfferRequestWithParams(), ...getStatsRequestsWithParams()])
          .then(async (responses) => {
            const [offerDataResponse, cursorOfferStatsResponse, promoOfferStatsResponse] =
              responses;

            if (cursorOfferStatsResponse.status === 200 && cursorOfferStatsResponse.data) {
              const { data: cursorData } = cursorOfferStatsResponse;
              setOfferStatsData(cursorData);
            }

            setPromoStatsData(promoOfferStatsResponse);

            if (
              offerDataResponse.status === 200 &&
              offerDataResponse.data &&
              store.selectedView !== 'outrights'
            ) {
              store.useOfferAdapter('INITIAL_OFFER_SETUP', offerDataResponse.data);
              store.getTournamentGroupingConfigs(
                game.value === 'LIVE',
                offerDataResponse.data.tournaments.map(({ id }) => id),
              );
            }
          })
          .then(() => {
            if (!params.favourites) {
              fetchAndCalculateNumberOfFavouriteEvents();
            }
          })
          .catch((error) => {
            console.error(`We've encountered error loading offer statistics.`, error);
          });
      } else {
        loadAndSubscribeOfferDataByFilters();
      }
      return;
    }

    if (game.value === 'SPORTS' && oldParams.view === 'search') {
      loadAndSubscribeOfferDataByFilters();
    }
  });

  // Emit SDK function to notify parent app about route change
  try {
    if (integrator) integrator.navigationChanged({ path: window.location.pathname || '' });
  } catch (e) {
    // Do nothing.
  }
});

const AppMobile = defineAsyncComponent({
  loader: () => import('@/components/mobile/Mobile.vue'),
});

const AppDesktop = defineAsyncComponent({
  loader: () => import('@/components/desktop/Desktop.vue'),
});

onBeforeUnmount(() => {
  clearInterval(autoReloadWalletsInterval);
});
</script>

<template>
  <component
    :is="isMobile ? AppMobile : AppDesktop"
    v-if="appReadyForLoad" />
</template>
