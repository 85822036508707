<script setup>
import { computed } from 'vue';

const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'md',
    validator(value) {
      return ['2xs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl'].includes(
        value,
      );
    },
  },
  opacity: {
    type: String,
    validator(value) {
      return [undefined, 'low', 'mid'].includes(value);
    },
  },
  selected: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const classes = computed(() => ({
  [props.icon]: props.icon,
  [`icon--${props.size}`]: props.size,
  [`icon--opacity-${props.opacity}`]: props.opacity,
  [`icon--selected`]: props.selected,
}));

const styles = computed(() => ({
  color: props.color,
}));
</script>

<template>
  <i
    :class="['icon n-i', classes]"
    :style="styles"
    aria-hidden="true">
  </i>
</template>

<style lang="scss">
$font-icon-path: 'https://assets.nsoft-cdn.com/public/gravity/icons/nfont';
$font-icon-name: 'NFont';
@import '@nsftx/nfont/css/_icons.scss';
@import '@/assets/styles/utility/_flags.scss';

.icon {
  font-size: var(--font-base);
  color: inherit;

  &.icon--opacity-mid {
    opacity: 0.6;
  }

  &.icon--opacity-low {
    opacity: 0.3;
  }

  &--selected {
    color: var(--primary);
  }
}

$iconSizes: (
  '2xs': 0.625rem,
  'xs': 0.75rem,
  'sm': 0.875rem,
  'md': 1rem,
  'lg': 1.125rem,
  'xl': 1.25rem,
  '2xl': 1.5rem,
  '3xl': 1.875rem,
  '4xl': 2.25rem,
  '5xl': 3rem,
  '6xl': 8rem,
);

@each $prop, $value in $iconSizes {
  .icon--#{$prop} {
    font-size: $value;
  }
}
</style>
