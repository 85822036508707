import { createRouter, createWebHistory } from 'vue-router';

import BettingArea from '@/views/BettingArea.vue';
import BetDetails from '@/views/BetDetails.vue';
import { isMobile } from '@/utils/helpers.js';

const scrollPositions = {};

const isLiveOrPrematch = (routeName) => ['live', 'sports'].includes(routeName);
const isMobileTransition = (to, from) =>
  isMobile &&
  !(to.name === 'live' && from.name === 'prematch') &&
  !(to.name === 'prematch' && from.name === 'live');

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    { path: '/', redirect: isMobile ? '/home' : '/sports' },
    {
      path: '/home/:filters*',
      name: 'home',
      component: BettingArea,
    },
    {
      path: '/sports/:filters*',
      name: 'sports',
      component: BettingArea,
    },
    {
      path: '/live/:filters*',
      name: 'live',
      component: BettingArea,
    },
    {
      path: '/bet-details',
      name: 'bet-details',
      component: BetDetails,
    },
  ],
  scrollBehavior: (to, from) => {
    if (!isMobileTransition(to, from)) return { top: 0 };

    const list = document.querySelector('.offer-list');
    if (isLiveOrPrematch(to.name) && !/(v_|e_)/.test(to.fullPath) && list)
      list.scrollTo({ top: scrollPositions?.offer ?? 0 });
    else {
      if (list) list.scrollTo({ top: 0 });
    }
  },
});

router.beforeEach((to, from, next) => {
  if (!isMobileTransition(to, from)) {
    scrollPositions['offer'] = 0;
    return next();
  }

  if (isLiveOrPrematch(from.name) && !/(v_|e_)/.test(from.fullPath))
    scrollPositions['offer'] = document.querySelector('.offer-list')?.scrollTop;

  next();
});

export default router;
