<template>
  <div
    v-if="bet"
    class="bet-details">
    <div class="bet-details__title-container">
      <div class="bet-details__title">
        <span>{{ betDetailsTitle }}</span>
        <StatusBadge
          :type="aggregatedStatus"
          :status="betStatusAndPayout" />
      </div>

      <div class="bet-details__subtitle">
        <span>{{ betPlacedAt }}</span>
        · {{ store.getTranslation('bet_id') }}:
        <span class="bet-id">{{ betId }}</span>
      </div>

      <button
        v-if="hasCloseButton"
        class="bet-details__close"
        :title="store.getTranslation('general_close')"
        @click="store.toggleBetPreview(false)">
        <Icon
          icon="n-i-close-a"
          size="xl" />
      </button>
    </div>

    <BetDetailsBody
      :bet="bet"
      show-status
      show-results />

    <BetDetailsActions
      :has-rebet="hasRebet"
      :has-cancel="isBetCancellable"
      :bet="bet"
      :max-win="+winningsToDisplay?.total"
      @cashout-bet="handleCashout(bet.betProfileId, bet.cashout, 'ticket check')"
      @rebet-bet="handleRebetAction"
      @cancel-bet="emitBetCancel" />
  </div>
</template>

<script setup>
import { computed, nextTick, onBeforeMount, ref, toRef } from 'vue';
import { useStore } from '@/stores/store.js';
import useBetDetails from '@/composables/bet-list/useBetDetails';
import Icon from '@/components/common/Icon.vue';
import BetDetailsBody from '@/components/desktop/common/BetDetailsBody.vue';
import BetDetailsActions from '@/components/desktop/common/BetDetailsActions.vue';
import StatusBadge from '@/components/common/StatusBadge.vue';

const props = defineProps({
  bet: {
    type: [Object, null],
    required: true,
    default: null,
  },
  hasCloseButton: {
    type: Boolean,
    required: false,
    default: false,
  },
  showRebetAction: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['cancelBet']);
const store = useStore();

const betRef = toRef(props, 'bet');
const {
  betDetailsTitle,
  aggregatedStatus,
  betStatusAndPayout,
  isBetCancellable,
  betPlacedAt,
  betEvents,
  systems,
  totalCombinations,
  betId,
  winningsToDisplay,
  handleCashout,
} = useBetDetails(betRef);

// TODO: Implement bet cancel
const emitBetCancel = () => emit('cancelBet');

const availableEvents = ref([]);

const hasRebet = computed(() => {
  return (
    props.showRebetAction && availableEvents.value.length > 0 && aggregatedStatus.value === 'OPEN'
  );
});

const handleRebetAction = async () => {
  availableEvents.value = await store.getAvailableEvents(betEvents.value);

  if (!availableEvents.value.length) {
    store.displayRebetUnavailableMessage();
    return;
  }

  const rebetSuccess = await store.rebet({
    bet: props.bet,
    events: availableEvents.value,
    systems,
    totalCombinations,
    allEventsAvailable: availableEvents.value.length === betEvents.value.length,
  });

  window.pushEventToGoogleTagManager('rebet_selected');

  if (!rebetSuccess) return;

  store.toggleBetPreview(false);
  store.setActiveTicketTab('betslip');

  nextTick(() => store.subscribeBetEvents(availableEvents.value));
};

onBeforeMount(async () => {
  availableEvents.value = await store.getAvailableEvents(betEvents.value);
  if (!availableEvents.value.length) return;

  store.subscribeBetEvents(availableEvents.value);

  for (const event of availableEvents.value ?? []) {
    if (!event?.detailedPlayStatus) continue;
    for (const [id] of store.betDetailsSelections) {
      if (event.id === +id)
        store.betDetailsSelections.get(id).detailedPlayStatus = event.detailedPlayStatus;
    }
  }
});
</script>

<style lang="scss">
:root {
  --bet-details-subtitle-color: var(--text-primary-2);
  --bet-details-bet-id-color: var(-text-alpha);
}
</style>

<style scoped lang="scss">
.bet-details {
  .bet-id {
    font-weight: bold;
    color: var(--bet-details-bet-id-color);
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 0.38rem;
    text-transform: capitalize;

    span:first-child {
      font-size: 1.5rem;
      font-weight: 700;
      margin-right: 0.5rem;
    }

    &-container {
      position: relative;
      margin-bottom: var(--gap-24);
    }
  }

  &__subtitle {
    font-size: 0.875rem;
    color: var(--bet-details-subtitle-color);
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
  }
}
</style>
