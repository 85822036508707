<script setup>
import { computed, ref, onMounted } from 'vue';
import { onClickOutside } from '@vueuse/core';

const props = defineProps({
  height: String,
  width: String,
  maxWidth: String,
  transparent: Boolean,
});

const emit = defineEmits(['closeModal']);

const styles = computed(() => ({
  height: props.height,
  width: props.width,
  'max-width': props.maxWidth,
}));

const appModal = ref(null);
const closeModal = () => emit('closeModal');

onMounted(() => onClickOutside(appModal, closeModal));
</script>

<template>
  <div class="app-modal__overlay">
    <div
      ref="appModal"
      :class="['app-modal', { 'app-modal--transparent': transparent }]"
      :style="styles">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-modal {
  position: absolute;
  width: 95vw;
  min-width: 400px;
  height: 75vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: var(--border-radius-xs);
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 80%;
  background-color: var(--card-background);

  &--transparent {
    background-color: transparent;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--background-delta);

    @include z-index(overlay);
  }
}
</style>
