import axios from 'axios';

const { VITE_CASHOUT_API } = import.meta.env;

const http = axios.create({
  baseURL: VITE_CASHOUT_API,
});

export async function getCashoutBetList(data = {}) {
  return http({
    method: 'GET',
    url: `/tenants/${window.tenantUuid}/players/${window.punter?.id}/bet-profiles`,
    headers: {
      Authorization: `Bearer ${punterToken || ''}`,
    },
    params: data,
  });
}

export async function calculateCashout(data) {
  try {
    const response = await http({
      method: 'POST',
      url: `/tenants/${window.tenantUuid}/players/${window.punter?.id}/bet-profiles/status`,
      headers: {
        Authorization: `Bearer ${punterToken || ''}`,
      },
      data,
    });

    return response.data;
  } catch (e) {
    console.log(e);
  }
}

export async function executeCashout(id, data) {
  return http({
    method: 'POST',
    url: `/tenants/${window.tenantUuid}/players/${window.punter?.id}/bet-profiles/${id}`,
    headers: {
      Authorization: `Bearer ${punterToken || ''}`,
    },
    data,
  });
}

export async function addAutoCashoutRule(betProfileId, data) {
  return http({
    method: 'POST',
    url: `/tenants/${window.tenantUuid}/players/${window.punter?.id}/bet-profiles/${betProfileId}/auto-cashout-rules`,
    headers: {
      Authorization: `Bearer ${punterToken || ''}`,
    },
    data,
  });
}

export async function updateAutoCashoutRule(ruleId, data) {
  return http({
    method: 'PUT',
    url: `/tenants/${window.tenantUuid}/players/${window.punter?.id}/auto-cashout-rules/${ruleId}`,
    headers: {
      Authorization: `Bearer ${punterToken || ''}`,
    },
    data,
  });
}

export async function deleteAutoCashoutRule(ruleId) {
  return http({
    method: 'DELETE',
    url: `/tenants/${window.tenantUuid}/players/${window.punter?.id}/auto-cashout-rules/${ruleId}`,
    headers: {
      Authorization: `Bearer ${punterToken || ''}`,
    },
  });
}
