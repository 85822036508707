/**
 * Application Configuration Object.
 * @category Application config
 * @typedef {Object} AppConfig
 * @property {BetslipConfig} betslip - Configuration related to the betslip.
 * @property {boolean} promotionalBanners - Indicates whether promotional banners are enabled.
 * @property {boolean} promotedEvents - Indicates whether promoted events are enabled.
 * @property {string} defaultOfferView - Default offer view. Acceptable values are: standard, asian.
 * @property {Map<string, string[]>} availableMobileOfferViews - Map of available mobile offer views.
 * Possible keys: standard, asian, indonesian.
 * Possible layouts: horizontal, horizontal-named, vertical, 3-market, 6-market
 * @property {Map<string, string[]>} availableDesktopOfferViews - Map of available desktop offer views.
 * Possible keys: standard, asian, indonesian.
 * Possible layouts: horizontal, horizontal-named, single, vertical, vertical-highlighted
 * @property {string} defaultOddsFormat - Default odds format. Acceptable values are: american, decimal, fractional, hongkong, indonesian, malaysian.
 * @property {Set<string>} availableOddsFormats - Set of available odds formats.
 *    Available odd formats are: decimal, american, fractional, hongkong, indonesian, malaysian.
 * @property {string} defaultTheme - Default theme. Acceptable values are: dark, light, obsidian_blue.
 * @property {Set<string>} availableThemes - Set of available themes.
 * @property {HeaderConfig} header - Configuration related to the application header.
 * @property {SupplementalConfig} supplemental - Configuration related to supplemental settings.
 * @property {SportradarWidgets} srWidgets - Configuration related to the Sportradar widgets.
 * @property {boolean} sendScrollPositionInfo - Indicates if app will send current scroll position to parent integrator.
 */

/**
 * Configuration related to the betslip.
 * @category Application config
 * @typedef {Object} BetslipConfig
 * @property {PredefinedStakeConfig} predefinedStake - Configuration related to predefined stakes.
 * @property {StakeConfig} stake - Configuration related to default stake.
 * @property {string} defaultOddsChangesOption - Default oddsChange option.
 *    Acceptable values are listed in availableOddsChangesOptions Set.
 * @property {Set<string>} availableOddsChangesOptions - Set of available oddsChange options.
 *    Available oddsChange options are: accept_any_changes, accept_higher_odds and dont_accept_any_changes.
 * @property {boolean} clearBetslipAfterPayment - Indicates whether Betslip should be cleared after payment.
 * @property {Set<string>} betTypes - List of available bet types, supported values are: single, combo system.
 */

/**
 * Configuration related to predefined stakes.
 * @category Application config
 * @typedef {Object} PredefinedStakeConfig
 * @property {number[]} default - Array of predefined stakes used as default.
 * @property {number[]} $currency - Array of predefined stakes per currency. Overrides default property.
 */

/**
 * Configuration related to default stake.
 * @category Application config
 * @typedef {Object} StakeConfig
 * @property {number} default - Default stake. Acceptable value is number (or string) rounded to max 2 decimals.
 * @property {number} $currency - Default stake per currency. Overrides default property.
 */

/**
 * Configuration related to supplemental settings.
 * @category Application config
 * @typedef {Object} SupplementalConfig
 * @property {number[]} list - Array of supplemental list items.
 * @property {SupplementalDeviceConfig} desktop - Configuration related to desktop supplemental settings.
 * @property {SupplementalDeviceConfig} mobile - Configuration related to mobile supplemental settings.
 * @property {SupplementalDeviceConfigPerOfferView} $offerView - Configure related to supplemental settings per offer view.
 */

/**
 * Configuration related to the application header.
 * @category Application config
 * @typedef {Object} HeaderConfig
 * @property {boolean} enabled - Indicates whether the header is enabled.
 * @property {string} depositExternalUrl - URL for external deposit link.
 */

/**
 * Configure related to supplemental settings per offer view.
 * @category Application config
 * @typedef {Object} SupplementalDeviceConfigPerOfferView
 * @property {SupplementalDeviceConfig} desktop - Configuration related to desktop supplemental settings.
 * @property {SupplementalDeviceConfig} mobile - Configuration related to mobile supplemental settings.
 */

/**
 * Configuration related to supplemental settings per device.
 * @category Application config
 * @typedef {Object} SupplementalDeviceConfig
 * @property {string} overview - Overview setting for supplemental names.
 * @property {string} eventview - Eventview setting for supplemental names.
 * @property {string} betslip - Betslip setting for supplemental names.
 */

/**
 * Configuration related to the application header.
 * @category Application config
 * @typedef {Object} HeaderConfig
 * @property {boolean} enabled - Indicates whether the header is enabled.
 * @property {string} depositExternalUrl - URL for external deposit link.
 */

/**
 * Configuration related to the Sportradar widgets.
 * @category Sportradar widgets
 * @typedef {Object} SportradarWidgets
 * @property {string} clientId - The client ID used for SR Widgets.
 * @property {Object} liveMatchTracker - Configuration for the Live Match Tracker (LMT) widget.
 * @property {boolean} liveMatchTracker.enabled - Flag to enable or disable the LMT.
 * @property {Object} betAssist - Configuration for the Bet Assist widget.
 * @property {boolean} betAssist.enabled - Flag to enable or disable the bet assist feature.
 */

export default {
  betslip: {
    predefinedStake: {
      default: [10, 50, 70, 100, 500, 1000],
    },
    stake: {
      default: 2,
    },
    /*
      ATM we're not sure how Backend will treat OddsChangesOptions on bet placement.
      @ToDo: Update OddsChangeOption key names (update JSDoc definition above as well),
        once the feature is implemented on Backend side.
    */
    defaultOddsChangesOption: 'accept_any_changes',
    availableOddsChangesOptions: new Set([
      'accept_any_changes',
      'accept_higher_odds',
      'dont_accept_any_changes',
    ]),
    clearBetslipAfterPayment: true,
    betTypes: new Set(['single', 'combo', 'multiples']),
    displayMultiplesBetTypes: true,
    numberOfBetslips: 1,
  },
  defaultMobileOfferView: 'standard',
  defaultDesktopOfferView: 'standard',
  availableDesktopOfferViews: new Map([
    ['standard', ['horizontal', 'single']],
    ['asian', ['single-named', 'vertical']],
    ['indonesian', ['vertical-highlighted']],
  ]),
  availableMobileOfferViews: new Map([
    ['standard', ['horizontal', 'vertical']],
    ['asian', ['horizontal-named', '3-market']],
    ['indonesian', ['6-market']],
  ]),
  defaultOddsFormat: 'decimal',
  availableOddFormats: new Set([
    'decimal',
    'american',
    'fractional',
    'hongkong',
    'indonesian',
    'malaysian',
  ]),
  defaultTheme: 'dark',
  availableThemes: new Set(['dark', 'light', 'obsidian_blue']),
  promotionalBanners: false,
  promotedEvents: true,
  outrightsView: false,
  header: {
    enabled: false,
    depositExternalUrl: '',
    logoFileName: 'nsoft88-logo.png',
  },
  betAssistEnabled: false,
  promotedOffer: true,
  boostedBetslips: true,
  supplemental: {
    list: [1],
    desktop: {
      overview: null,
      eventview: null,
      betslip: null,
      betList: '1',
    },
    mobile: {
      overview: null,
      eventview: null,
      betslip: null,
      betList: '1',
    },

    indonesian: {
      desktop: {
        overview: '1',
        eventview: '1',
        betslip: '1',
        betList: '1',
      },
      mobile: {
        overview: '1',
        eventview: '1',
        betslip: '1',
        betList: '1',
      },
    },
  },
  srWidgets: {
    url: 'https://widgets.sir.sportradar.com/326ae239e3a0fb8b9034cb6ee2ed9535/widgetloader',
    liveMatchTracker: {
      enabled: false,
    },
    betAssist: {
      enabled: false,
    },
  },
  defaultRoute: {
    prematch: {
      time: 'all',
      offer: 'sport',
    },
    live: {
      offer: 'sport',
    },
  },
  googleTagManager: {
    enabled: true,
    id: 'G-868SBRFFP7',
  },
  boostedBetslipsPosition: {
    mobile: 'TOP',
    desktop: 'TOP',
  },
  vaixPosition: {
    mobile: 2,
    desktop: 2,
  },
  sendScrollPositionInfo: false,

  homepage: {
    quicklinks: ['stream', 'upcoming', 'promoted', 'last_bets', 'outrights'],
    widgets: [
      {
        name: 'banner',
        position: 1,
        props: {
          banner: {
            action: {
              external: false,
              target: '_self',
              path: '/sports/d_all/f_true',
            },
            styles: {
              'background-image': 'url(/images/homepage/basketball.webp)',
              height: '107px',
            },
          },
          textBlock: {
            styles: {},
            labels: [
              { value: 'Basketball', styles: {} },
              {
                value: 'Odds Boost',
                style: {
                  color: 'var(--primary)',
                },
              },
            ],
          },
        },
      },
      { name: 'quick-links', position: 2 },
      { name: 'recommended-betslips', position: 3 },
      { name: 'in-play', position: 4 },
      {
        name: 'banner',
        position: 5,
        props: {
          banner: {
            action: 'FIRST_CATALOG',
            styles: {
              'background-image': 'url(/images/homepage/blno-women.png)',
              'background-position-y': '35%',
              height: '107px',
            },
          },
          textBlock: {
            styles: {},
            labels: [
              { value: '2024', style: { color: 'var(--primary)' } },
              {
                value: 'BLNO Women',
                style: {},
              },
            ],
          },
        },
      },
      { name: 'promo-carousel', position: 6 },
      { name: 'promoted-events', position: 7 },
      {
        name: 'banner',
        position: 8,
        props: {
          banner: {
            action: {
              external: false,
              target: '_self',
              path: '/sports/v_outrights',
            },
            styles: {
              'background-image': 'url(/images/homepage/outrights-bets.png)',
              height: '107px',
            },
          },
          textBlock: {
            styles: { bottom: '1rem' },
            labels: [
              { value: 'Outrights', style: {} },
              { value: 'Bets', style: {} },
            ],
          },
        },
      },
      { name: 'upcoming', position: 9, props: { numberOfEvents: 5 } },
    ],
  },
};
